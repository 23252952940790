import React from 'react';
import "./testimonials.css";
import Image1 from "../../assets/maycon.jpg";
import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    id: 1,
    image: Image1,
    title: "Maycon Queiros",
    subtitle: "Líder Técnico em Analytics Engineering na Bemobi",
    comment:
      "Foi uma estagiária valiosa no time de Analytics Engineering, responsável pela construção e manutenção de pipelines de dados. Demonstrou sólido conhecimento em SQL e um forte interesse por programação e automação de processos. Se destacou contribuindo para entregas de qualidade e dentro dos prazos além de foco no planejamento e desenvolvimento de carreira. Recomendo Lívia como uma profissional com boa aptidão técnica, com potencial para agregar valor a qualquer equipe.",
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials container section">
      <h2 className="section__title">Recomendações</h2>

      <Swiper className="testimonials__container grid"
      modules={[Pagination]}
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      grabCursor={true}
      pagination={{ clickable: true }}
      >
        {data.map(({id, image, title, subtitle, comment}) => {
          return (
            <SwiperSlide className="testimonials__item" key={id}>
              <div className="thumb">
                <img src={image} alt="" />
              </div>
              <h3 className='testimonials__title'>{title}</h3>
              <span className='subtitle'>{subtitle}</span>
              <div className="comment">{comment}</div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}

export default Testimonials