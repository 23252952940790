import React from 'react'

const AboutBox = () => {
  return (
    <div className="about__boxes grid">
        <div className="about__box">
            <i className="about__icon icon-fire"></i>

            <div>
                <h3 className="about__title">22</h3>
                <span className="about__subtitle">Projetos completos</span>
            </div>
        </div>

        <div className="about__box">
            <i className="about__icon icon-cup"></i>

            <div>
                <h3 className="about__title">20000</h3>
                <span className="about__subtitle">Copos de coca-cola</span>
            </div>
        </div>

        <div className="about__box">
            <i className="about__icon icon-people"></i>

            <div>
                <h3 className="about__title">1ª</h3>
                <span className="about__subtitle">Experiência na área</span>
            </div>
        </div>

        <div className="about__box">
            <i className="about__icon icon-badge"></i>

            <div>
                <h3 className="about__title">6</h3>
                <span className="about__subtitle">Certificados</span>
            </div>
        </div>
    </div>
  )
}

export default AboutBox