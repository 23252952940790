import React from 'react';
import "./home.css";
import Me from "../../assets/fotinha.jpeg"
import HeaderSocials from './HeaderSocials';
import ScrollDown from './ScrollDown';
import Shapes from './Shapes';
import {useTypewriter, Cursor} from 'react-simple-typewriter';

const Home = () => {

  const[textName] = useTypewriter({
    words: ['Glória Braz'],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 100,
  });

  return (
    <section className="home container" id="home">
      <div className="intro"> 
        <img src={Me} alt="" className="home__img" />
        <h1 className="home__name"> {textName} <Cursor /> </h1>
        <h1>Desenvolvedora</h1>
        <span className="home__education"> 
          Full Stack
        </span>
        
      <HeaderSocials />

      <a href="#contact" className="btn"> Me contate</a>

      <ScrollDown />
      </div>

      <Shapes />
    </section>
  )
}

export default Home