import React from 'react'

const HeaderSocials = () => {
  return (
    <div className="home__socials">

        <a href="https://www.linkedin.com/in/codingloria/" 
        className="home__social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-linkedin"></i>
        </a>

        <a href="https://github.com/codingloria" 
        className="home__social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-github"></i>
        </a>

        <a href="mailto:codingloria@gmail.com" 
        className="home__social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa fa-envelope-o"></i>
        </a>
        
    </div>
  )
}

export default HeaderSocials