const Data = [
    {
      id: 1,
      category: "education",
      icon: "icon-graduation",
      year: "julho 2024 - em andamento",
      title: "Bacharelado em Engenharia de Software",
      desc: "Instituto INFNET *Formação prevista: junho 2028 *Acompanhe meus estudos pelo repositório do GitHub, clicando <a href='https://github.com/codinGloria/engenhariaDeSoftware' target='_blank'>aqui</a>.",
  },
    {
      id: 2,
      category: "education",
      icon: "icon-graduation",
      year: "janeiro 2022 - junho 2024",
      title: "Tecnólogo em Análise e Desenvolvimento de Sistemas",
      desc: "Estácio*  Formação em linguagens de programação, banco de dados, desenvolvimento web, redes e segurança da informação. Habilidades para atuar como desenvolvedor e analista de sistemas.",
    },
    {
      id: 3,
      category: "experience",
      icon: "icon-briefcase",
      year: "novembro 2024 - atualmente",
      title: "Estagiária Dev Backend @Akross",
      desc: "*Microsserviços, Java, AWS, Kubernetes, SpringBoot",
    },
    {
      id: 4,
      category: "experience",
      icon: "icon-briefcase",
      year: "abril 2024 - outubro 2024",
      title: "Estagiária em Governança de TI @SPC Brasil",
      desc: "*ITSM, Excel, RemedyForce, ITIL",
    },
    {
      id: 5,
      category: "experience",
      icon: "icon-briefcase",
      year: "julho 2022 - janeiro 2024",
      title: "Estagiária de Dados @Bemobi",
      desc: "*SQL, Bitbucket, JIRA, Confluence, Airflow, Tableau, Looker, Metodologias Ágeis (SCRUM e Kanban)",
    },
];

export default Data