import React from 'react';
import "./about.css";
import Image from "../../assets/aa.jpg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import AboutBox from './AboutBox';

ChartJS.register(ArcElement, Tooltip,Legend)

const About = () => {
  const data = {
    labels: ['Java', 'Angular', 'React'],
    datasets: [
      {
        data: [60, 20, 20],
        backgroundColor: ['#ff5063', '#4ed4b6', '#7171e6']
      }
    ]
  };

  return (
    <section className="about container section" id="about">
      <h2 className="section__title"> Sobre mim </h2>

      <div className="about__container grid">
        <img src={Image} alt="" className="about__img" />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
            Oii Jóia ✨ Que bom te ter aqui! <br/>
            Eu sou a Glória, uma mineira de 22 anos apaixonada por tecnologia, animes, livros e gatinhos 🥰 
            <br/> <br/>
            Formada em ADS e cursando Engenharia de Software no Instituto INFNET. 
            Tenho experiência em Engenharia de Dados, atuando com SQL, Tableau, Looker.
            <br/><br/> 
            Atualmente, sou Desenvolvedora Backend na Akross, trabalhando com Microsserviços, Java, Spring, AWS e Kubernetes.
            Sempre atuando com metodologias ágeis como SCRUM e Kanban.
            <br/><br/> 
            Meu objetivo final é construir uma carreira de sucesso, buscando uma vida estável e feliz fazendo o que amo: codar \(*-*)/
            </p>
            <a href="https://drive.google.com/uc?export=download&id=1U-FiTsFRH1BCZ8-TyGEjMP8bb0EnNI6z" className="btn" download>Baixar CV</a>
          </div>
          
          <div className="about__skills grid">
            <Pie
              data = {data}
              options= {{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
            <div className="legend-container">
              {data.labels.map((label, index) => (
                <div key={index} className="legend-badge">
                  <span className="legend-color" style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}></span>
                  <span className="legend-text">{label}</span>
                </div>
              ))}
            </div>
        </div>
      </div>
    </div>

      <AboutBox/>
    </section>
  )
}

export default About