import React from 'react';
import "./blog.css";

import Image2 from '../../assets/post2.png';
import Image3 from '../../assets/akross.jpg';
import Image1 from '../../assets/Post - Gupy.png';

const Blog = () => {
  return (
    <section className="blog container section" id="blog">
      <h2 className="section__title">Posts recentes</h2>

      <div className="blog__container grid">

      <div className="blog__card">
            <div className="blog__thumb">
              <span className="blog__category">Dicas Profissionais</span>
              <a href="https://www.linkedin.com/posts/codingloria_dicas-gupy-activity-7259882249691492352-pYEZ/"><img src={Image1} alt="" 
              className="blog__img" /></a>
            </div>
            <div className="blog__details">
              <h3 className="blog__title">Vencendo a Gupy!</h3>
              <div className="blog__meta">
                <span>06 Novembro, 2024</span>
                <span className="blog__dot">.</span>
                <span>Glória</span>
              </div>
            </div>
      </div>

      <div className="blog__card">
            <div className="blog__thumb">
              <span className="blog__category">Experiências</span>
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7227985669728407552/"><img src={Image3} alt="" 
              className="blog__img" /></a>
            </div>
            <div className="blog__details">
              <h3 className="blog__title">Venci a Gupy pela 3ªvez!</h3>
              <div className="blog__meta">
                <span>01 Novembro, 2024</span>
                <span className="blog__dot">.</span>
                <span>Glória</span>
              </div>
            </div>
        </div>


      <div className="blog__card">
          <div className="blog__thumb">
            <span className="blog__category">Eventos</span>
            <a href="https://www.linkedin.com/posts/codingloria_devfest-gdgbh-activity-7256651047543226369-zz6_"><img src={Image2} alt="" 
            className="blog__img" /></a>
          </div>
          <div className="blog__details">
            <h3 className="blog__title">DevFest BH</h3>
            <div className="blog__meta">
              <span>28 Outubro, 2024</span>
              <span className="blog__dot">.</span>
              <span>Glória</span>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Blog