import projeto1 from "../../assets/capaProjetos/passIn.png";
import projeto2 from "../../assets/capaProjetos/biblioteca.jpg";
import projeto3 from "../../assets/capaProjetos/CRUDagenciaViagens.png";
import projeto4 from "../../assets/capaProjetos/gerencEstoque.jpg";
import projeto5 from "../../assets/capaProjetos/petshop.jpg";
import projeto6 from "../../assets/capaProjetos/xadrez.jpg";
import projeto7 from "../../assets/capaProjetos/timeWise.png";
import projeto8 from "../../assets/capaProjetos/testePratico.png";
import projeto9 from "../../assets/capaProjetos/healing.png";
import projeto10 from "../../assets/capaProjetos/portfolio.png";

const Menu = [
    {
      id: 1,
      image: projeto1,
      title: "Organização de Eventos",
      category: ["React", "Java", "Integração BD"],
      githubLink: "https://github.com/codinGloria/nlwUnite-PassIn"
    },
    {
      id: 2,
      image: projeto2,
      title: "Gerenciamento Biblioteca",
      category: ["Java", "Integração BD"],
      githubLink: "https://github.com/codinGloria/academiaJava/tree/main/aula06/labs/biblioteca"
    },
    {
      id: 3,
      image: projeto3,
      title: "API Agência de Viagens",
      category: ["Java", "Spring"],
      githubLink: "https://github.com/codinGloria/CRUDagenciaViagens"
    },
    {
      id: 4,
      image: projeto4,
      title: "Gerenciamento de Estoque",
      category: ["Java", "Integração BD"],
      githubLink: "https://github.com/codinGloria/gerenciamentoEstoque"
    },
    {
      id: 5,
      image: projeto5,
      title: "Gerenciamento de Petshop",
      category: ["Java", "Integração BD"],
      githubLink: "https://github.com/codinGloria/academiaJava/tree/main/aula07/labs/PetShop"
    },
    {
      id: 6,
      image: projeto6,
      title: "Jogo de Xadrez",
      category: ["Java"],
      githubLink: "https://github.com/codinGloria/cursoJavaUdemy",
    },
    {
      id: 7,
      image: projeto7,
      title: "Produtividade e gestão pessoal - Time Wise",
      category: ["Java", "Spring", "Integração BD", "Angular"],
      githubLink: "https://github.com/codinGloria/projectTimeWise",
    },
    {
      id: 8,
      image: projeto8,
      title: "Gerenciamento de Funcionários",
      category: ["Java", "Integração BD"],
      githubLink: "https://github.com/codinGloria/projetoSecreto",
    },
    {
      id: 9,
      image: projeto9,
      title: "Plataforma para Clínicas Médicas",
      category: ["Python", "Django", "Integração BD"],
      githubLink: "https://github.com/codinGloria/psw10-Healing",
    },
    {
      id: 10,
      image: projeto10,
      title: "Portfólio Simples",
      category: ["HTML - CSS- JS"],
      githubLink: "https://github.com/codinGloria/portfolioHTMLCSS",
      liveLink: "https://codingloria.github.io/portfolioHTMLCSS/"
    }
  ];  

export default Menu